import { FETCH_CONTACT_DETAILS } from "./contact-details.actions";
import { SET_CONTACT_DETAILS } from "./contact-details.mutations";
import contactDetailsModel, {
  ContactDetailsModuleState,
  ContactDetailsEntity,
} from "./contact-details.model";

const state: ContactDetailsModuleState = {
  contactDetailsEntity: null,
};

const getters = {
  contactDetailsEntity(state: ContactDetailsModuleState): ContactDetailsEntity {
    return state.contactDetailsEntity;
  },
};

const actions = {
  async [FETCH_CONTACT_DETAILS]({ commit }, id: string): Promise<void> {
    commit(SET_CONTACT_DETAILS, await contactDetailsModel.get(id));
  },
};

const mutations = {
  [SET_CONTACT_DETAILS](
    state: ContactDetailsModuleState,
    contactDetailsEntity: ContactDetailsEntity
  ): void {
    state.contactDetailsEntity = contactDetailsEntity;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
