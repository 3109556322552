import { FETCH_QUOTE_PAGE } from "./quote-page.actions";
import { SET_QUOTE_PAGE } from "./quote-page.mutations";
import quotePageModel, {
  QuotePageModuleState,
  QuotePageEntity,
} from "./quote-page.model";

const state: QuotePageModuleState = {
  quotePageEntity: null,
};

const getters = {
  quotePageEntity(state: QuotePageModuleState): QuotePageEntity {
    return state.quotePageEntity;
  },
};

const actions = {
  async [FETCH_QUOTE_PAGE]({ commit }, id: string): Promise<void> {
    commit(SET_QUOTE_PAGE, await quotePageModel.get(id));
  },
};

const mutations = {
  [SET_QUOTE_PAGE](
    state: QuotePageModuleState,
    quotePageEntity: QuotePageEntity
  ): void {
    state.quotePageEntity = quotePageEntity;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
