import api from "@/store/api";
import { ContentfulEntity } from "@/store/contentful.types";

export type ProductPageModuleState = {
  solarProductPageEntity: ProductPageEntity;
  batteriesProductPageEntity: ProductPageEntity;
  // productResources: Array<ProductResourceEntity>;
  productResource: ProductResourceEntity;
};

export interface ProductPageEntity {
  id: string | null;
  productPageSplashImage: ContentfulEntity;
  productPageSplashText: string;
  productPageProductBlocks: Array<ContentfulEntity>;
}

export class ProductPageEntity {
  constructor({
    id = null,
    productPageSplashImage = null,
    productPageSplashText = null,
    productPageProductBlocks = [],
  } = {}) {
    this.id = id;
    this.productPageSplashImage = productPageSplashImage;
    this.productPageSplashText = productPageSplashText;
    this.productPageProductBlocks = productPageProductBlocks;
  }
}

export function responseAdapter(response) {
  const { fields, sys } =
    response.sys.type === "Array" ? response.items[0] : response;

  return new ProductPageEntity({ ...fields, ...sys });
}

export interface ProductResourceEntity {
  id: string | null;
  productBlockResourceNameText: string;
  productBlockResourceFile: unknown; // TODO: productBlockResourceFile should be typed
}

export class ProductResourceEntity {
  constructor({
    id = null,
    productBlockResourceNameText = null,
    productBlockResourceFile = null,
  } = {}) {
    this.id = id;
    this.productBlockResourceNameText = productBlockResourceNameText;
    this.productBlockResourceFile = productBlockResourceFile;
  }
}

export function productResourceResponseAdapter(response) {
  const { fields, sys } =
    response.sys.type === "Array" ? response.items[0] : response;

  return new ProductResourceEntity({ ...fields, ...sys });
}

export default {
  async get(id) {
    return responseAdapter(await api.getEntries({ "sys.id": id }));
  },
  async getProductResource(id) {
    return productResourceResponseAdapter(
      await api.getEntries({ "sys.id": id })
    );
  },
};
