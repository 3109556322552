import api from "@/store/api";
import { ContentfulEntityRichText } from "@/store/contentful.types";

export type ConditionsPageModuleState = {
  conditionsPageEntity: ConditionsPageEntity;
};

export interface ConditionsPageEntity {
  id: string | null;
  termsAndConditionsPageHeaderText: string;
  termsAndConditionsPageRichText: ContentfulEntityRichText;
}

export class ConditionsPageEntity {
  constructor({
    id = null,
    termsAndConditionsPageHeaderText = null,
    termsAndConditionsPageRichText = null,
  } = {}) {
    this.id = id;
    this.termsAndConditionsPageHeaderText = termsAndConditionsPageHeaderText;
    this.termsAndConditionsPageRichText = termsAndConditionsPageRichText;
  }
}

export function responseAdapter(response) {
  const { fields, sys } =
    response.sys.type === "Array" ? response.items[0] : response;

  return new ConditionsPageEntity({ ...fields, ...sys });
}

export default {
  async get(id) {
    return responseAdapter(await api.getEntries({ "sys.id": id }));
  },
};
