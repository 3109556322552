import {
  FETCH_SOLAR_PRODUCT_PAGE,
  FETCH_BATTERIES_PRODUCT_PAGE,
  FETCH_PRODUCT_RESOURCE,
} from "./product-page.actions";
import {
  SET_SOLAR_PRODUCT_PAGE,
  SET_BATTERIES_PRODUCT_PAGE,
  SET_PRODUCT_RESOURCE,
} from "./product-page.mutations";
import productPageModel, {
  ProductPageModuleState,
  ProductPageEntity,
  ProductResourceEntity,
} from "./product-page.model";

const state: ProductPageModuleState = {
  solarProductPageEntity: null,
  batteriesProductPageEntity: null,
  productResource: null,
};

const getters = {
  solarProductPageEntity(state: ProductPageModuleState): ProductPageEntity {
    return state.solarProductPageEntity;
  },
  batteriesProductPageEntity(state: ProductPageModuleState): ProductPageEntity {
    return state.batteriesProductPageEntity;
  },
  productResource(state: ProductPageModuleState): ProductResourceEntity {
    return state.productResource;
  },
};

const actions = {
  async [FETCH_SOLAR_PRODUCT_PAGE]({ commit }, id: string): Promise<void> {
    commit(SET_SOLAR_PRODUCT_PAGE, await productPageModel.get(id));
  },
  async [FETCH_BATTERIES_PRODUCT_PAGE]({ commit }, id: string): Promise<void> {
    commit(SET_BATTERIES_PRODUCT_PAGE, await productPageModel.get(id));
  },
  async [FETCH_PRODUCT_RESOURCE]({ commit }, id: string): Promise<void> {
    commit(SET_PRODUCT_RESOURCE, await productPageModel.getProductResource(id));
  },
};

const mutations = {
  [SET_SOLAR_PRODUCT_PAGE](
    state: ProductPageModuleState,
    solarProductPageEntity: ProductPageEntity
  ): void {
    state.solarProductPageEntity = solarProductPageEntity;
  },
  [SET_BATTERIES_PRODUCT_PAGE](
    state: ProductPageModuleState,
    batteriesProductPageEntity: ProductPageEntity
  ): void {
    state.batteriesProductPageEntity = batteriesProductPageEntity;
  },
  [SET_PRODUCT_RESOURCE](
    state: ProductPageModuleState,
    productResource: ProductResourceEntity
  ): void {
    state.productResource = productResource;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
