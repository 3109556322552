<template>
  <div v-if="!this.isLoading">
    <div class="navbar-desktop">
      <div class="navbar-desktop__logo">
        <img :src="getNavbarLogoImageUrl()" />
      </div>
      <div class="navbar-desktop__links">
        <ul>
          <li v-for="item in this.navbarLinksModel.model" :key="item.label">
            <Applink
              :key="item.label"
              :to="item.to"
              :activeClass="{
                'applink--active--highlight': item.highlight,
                'applink--active': !item.highlight,
              }"
              :inactiveClass="{
                'applink--inactive--highlight': item.highlight,
                'applink--inactive': !item.highlight,
              }"
            >
              {{ item.label }}
            </Applink>
            <ul v-if="item.items">
              <li v-for="subitem in item.items" :key="subitem.label">
                <Applink :key="subitem.label" :to="subitem.to">
                  {{ subitem.label }}
                </Applink>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="navbar-desktop__after" />
    </div>
    <div class="navbar-mobile">
      <!-- <button class="navbar-mobile__menu-button" @click="this.toggleMobileMenu">
        <font-awesome-icon
          :icon="['fas', 'bars']"
          size="2x"
          class="navbar-mobile__menu-button__icon"
          :class="{
            'navbar-mobile__menu-button__icon--active': this.isMobileMenuActive,
          }"
        />
      </button> -->
      <font-awesome-icon
        :icon="['fas', 'bars']"
        size="2x"
        class="navbar-mobile__menu-button__icon"
        :class="{
          'navbar-mobile__menu-button__icon--active': this.isMobileMenuActive,
        }"
        @click="this.toggleMobileMenu"
      />
      <span class="navbar-mobile__menu-header">Spartan Energy</span>
      <div class="navbar-mobile__icon">
        <img src="/spartan-energy-icon-black.png" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import "./navbar.scss";
import { Options, Vue } from "vue-class-component";
import { h, resolveComponent } from "vue";
// import { Prop, Watch } from "vue-property-decorator";
import Applink from "@/components/applink/applink.vue";
import { getImageUrlFromContentfulEntity } from "@/store/utils";
import { FETCH_NAVBAR } from "@/store/modules/navbar/navbar.actions";
import { NAVBAR_ENTRY_ID } from "@/store/api.config";
import { NavbarEntity } from "@/store/modules/navbar/navbar.model";
import { NavbarLinksModel } from "./navbar.types";

import { NMenu } from "naive-ui";
import { RouterLink } from "vue-router";

@Options({
  name: "Navbar",
  components: {
    Applink,
    NMenu,
  },
})
export default class Navbar extends Vue {
  //#region Component
  isLoading = true;
  navbarEntity: NavbarEntity | null = null;
  navbarLinksModel: NavbarLinksModel | null = null;

  isMobileMenuActive = false;
  activeKey: null;
  menuOptions: Record<string, any> = [];
  //#endregion

  //#region Hooks
  created(): void {
    this.setup();
  }

  async setup(): Promise<void> {
    await this.$store.dispatch(FETCH_NAVBAR, NAVBAR_ENTRY_ID);
    this.navbarEntity = this.$store.getters.navbarEntity;
    this.navbarLinksModel = this.navbarEntity.navbarLinksModel;

    this.menuOptions = this.navbarLinksModel.model.map((navItem) => ({
      label: () =>
        h(
          RouterLink,
          { to: { path: navItem.to } },
          { default: () => navItem.label }
        ),
      key: navItem.label,
      children: navItem.items?.map((navItemChild) => ({
        label: () =>
          h(
            RouterLink,
            { to: { path: navItemChild.to } },
            { default: () => navItemChild.label }
          ),
        key: navItemChild.label,
      })),
    }));

    this.isLoading = false;
  }
  //#endregion

  //#region Functions
  getNavbarLogoImageUrl(): string {
    return getImageUrlFromContentfulEntity(this.navbarEntity.navbarLogoImage);
  }

  resetMobileMenu(): void {
    this.isMobileMenuActive = false;
  }

  toggleMobileMenu(): void {
    this.isMobileMenuActive = !this.isMobileMenuActive;
    this.$emit("toggleMobileMenu");
  }
  //#endregion
}
</script>
