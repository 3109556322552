import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import UnderConstructionPage from "@/views/under-construction-page/under-construction-page.vue";

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/",
  //   name: "UnderConstructionPage",
  //   component: UnderConstructionPage,
  // },
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "LandingPage",
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/landing-page/landing-page.vue"
      ),
  },
  {
    path: "/about",
    name: "AboutPage",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/about-page/about-page.vue"
      ),
  },
  {
    path: "/solar",
    name: "SolarPage",
    component: () =>
      import(
        /* webpackChunkName: "solar" */ "../views/solar-page/solar-page.vue"
      ),
  },
  {
    path: "/batteries",
    name: "BatteriesPage",
    component: () =>
      import(
        /* webpackChunkName: "batteries" */ "../views/batteries-page/batteries-page.vue"
      ),
  },

  {
    path: "/our-work",
    name: "GalleryPage",
    component: () =>
      import(
        /* webpackChunkName: "gallery" */ "../views/gallery-page/gallery-page.vue"
      ),
  },
  {
    path: "/news",
    name: "NewsPage",
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/news-page/news-page.vue"),
  },
  {
    path: "/contact",
    name: "ContactPage",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/contact-page/contact-page.vue"
      ),
  },
  {
    path: "/quote",
    name: "QuotePage",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/quote-page/quote-page.vue"
      ),
  },
  {
    path: "/terms-and-conditions",
    name: "ConditionsPage",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/conditions-page/conditions-page.vue"
      ),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPage",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/privacy-page/privacy-page.vue"
      ),
  },
  {
    path: "/our-deals",
    name: "DealsPage",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/deals-page/deals-page.vue"
      ),
  },
];

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
});

export default router;
