import api from "@/store/api";
import {
  ContentfulEntity,
  ContentfulEntityRichText,
} from "@/store/contentful.types";

export type LandingPageModuleState = {
  landingPageEntity: LandingPageEntity;
};

export interface LandingPageEntity {
  id: string | null;
  landingPageSplashImage: ContentfulEntity;
  landingPageSplashText: string;
  landingPageDealsRichText: ContentfulEntityRichText;
  landingPageHeader: string;
  landingPageRichText: ContentfulEntityRichText;
}

export class LandingPageEntity {
  constructor({
    id = null,
    landingPageSplashImage = null,
    landingPageSplashText = null,
    landingPageDealsRichText = null,
    landingPageHeader = null,
    landingPageRichText = null,
  } = {}) {
    this.id = id;
    this.landingPageSplashImage = landingPageSplashImage;
    this.landingPageSplashText = landingPageSplashText;
    this.landingPageDealsRichText = landingPageDealsRichText;
    this.landingPageHeader = landingPageHeader;
    this.landingPageRichText = landingPageRichText;
  }
}

export function responseAdapter(response) {
  const { fields, sys } =
    response.sys.type === "Array" ? response.items[0] : response;

  return new LandingPageEntity({ ...fields, ...sys });
}

export default {
  async get(id) {
    return responseAdapter(await api.getEntries({ "sys.id": id }));
  },
};
