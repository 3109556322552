<template>
  <a
    v-if="isTelLink"
    v-bind="$attrs"
    :href="to"
    target="_blank"
    :class="isActive ? activeClass : inactiveClass"
  >
    <font-awesome-icon
      :icon="['fas', 'phone']"
      size="1x"
      style="margin-right: 4px"
    />
    <slot />
  </a>
  <a
    v-else-if="isMailToLink"
    v-bind="$attrs"
    :href="to"
    target="_blank"
    :class="isActive ? activeClass : inactiveClass"
  >
    <font-awesome-icon
      :icon="['fas', 'envelope']"
      size="1x"
      style="margin-right: 4px"
    />
    <slot />
  </a>
  <a
    v-else-if="isExternalLink"
    v-bind="$attrs"
    :href="to"
    target="_blank"
    :class="isActive ? activeClass : inactiveClass"
  >
    <slot />
  </a>
  <router-link
    v-else
    v-bind="$props"
    custom
    v-slot="{ isActive, href, navigate }"
  >
    <a
      v-bind="$attrs"
      :href="href"
      @click="navigate"
      :class="isActive ? activeClass : inactiveClass"
    >
      <slot />
    </a>
  </router-link>
</template>

<script lang="ts">
import "./applink.scss";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: "Applink",
  inheritAttrs: false,
})
export default class Applink extends Vue {
  //#region Component
  @Prop() to: string;
  @Prop({ default: "applink--active" }) activeClass: string;
  @Prop({ default: "applink--inactive" }) inactiveClass: string;

  //#endregion

  //#region Functions
  get isExternalLink(): boolean {
    return typeof this.to === "string" && this.to.startsWith("http");
  }

  get isTelLink(): boolean {
    return typeof this.to === "string" && this.to.startsWith("tel");
  }

  get isMailToLink(): boolean {
    return typeof this.to === "string" && this.to.startsWith("mailto");
  }
  //#endregion
}
</script>
