import api from "@/store/api";
import { ContentfulEntity } from "@/store/contentful.types";

export type UnderConstructionPageModuleState = {
  underConstructionPageEntity: UnderConstructionPageEntity;
};

export interface UnderConstructionPageEntity {
  id: string | null;
  underConstructionPageLogoImage: ContentfulEntity;
  underConstructionPageText: string;
}

export class UnderConstructionPageEntity {
  constructor({
    id = null,
    underConstructionPageLogoImage = null,
    underConstructionPageText = null,
  } = {}) {
    this.id = id;
    this.underConstructionPageLogoImage = underConstructionPageLogoImage;
    this.underConstructionPageText = underConstructionPageText;
  }
}

export function responseAdapter(response): UnderConstructionPageEntity {
  const { fields, sys } =
    response.sys.type === "Array" ? response.items[0] : response;

  return new UnderConstructionPageEntity({ ...fields, ...sys });
}

export default {
  async get(id) {
    return responseAdapter(await api.getEntries({ "sys.id": id }));
  },
};
