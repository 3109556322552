import { FETCH_FOOTER } from "./footer.actions";
import { SET_FOOTER } from "./footer.mutations";
import footerModel, { FooterModuleState, FooterEntity } from "./footer.model";

const state: FooterModuleState = {
  footerEntity: null,
};

const getters = {
  footerEntity(state: FooterModuleState): FooterEntity {
    return state.footerEntity;
  },
};

const actions = {
  async [FETCH_FOOTER]({ commit }, id: string): Promise<void> {
    commit(SET_FOOTER, await footerModel.get(id));
  },
};

const mutations = {
  [SET_FOOTER](state: FooterModuleState, footerEntity: FooterEntity): void {
    state.footerEntity = footerEntity;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
