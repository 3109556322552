import { FETCH_LANDING_PAGE } from "./landing-page.actions";
import { SET_LANDING_PAGE } from "./landing-page.mutations";
import landingPageModel, {
  LandingPageModuleState,
  LandingPageEntity,
} from "./landing-page.model";

const state: LandingPageModuleState = {
  landingPageEntity: null,
};

const getters = {
  landingPageEntity(state: LandingPageModuleState): LandingPageEntity {
    return state.landingPageEntity;
  },
};

const actions = {
  async [FETCH_LANDING_PAGE]({ commit }, id: string): Promise<void> {
    commit(SET_LANDING_PAGE, await landingPageModel.get(id));
  },
};

const mutations = {
  [SET_LANDING_PAGE](
    state: LandingPageModuleState,
    landingPageEntity: LandingPageEntity
  ): void {
    state.landingPageEntity = landingPageEntity;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
