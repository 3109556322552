import { FETCH_ABOUT_PAGE } from "./about-page.actions";
import { SET_ABOUT_PAGE } from "./about-page.mutations";
import aboutPageModel, {
  AboutPageModuleState,
  AboutPageEntity,
} from "./about-page.model";

const state: AboutPageModuleState = {
  aboutPageEntity: null,
};

const getters = {
  aboutPageEntity(state: AboutPageModuleState): AboutPageEntity {
    return state.aboutPageEntity;
  },
};

const actions = {
  async [FETCH_ABOUT_PAGE]({ commit }, id: string): Promise<void> {
    commit(SET_ABOUT_PAGE, await aboutPageModel.get(id));
  },
};

const mutations = {
  [SET_ABOUT_PAGE](
    state: AboutPageModuleState,
    aboutPageEntity: AboutPageEntity
  ): void {
    state.aboutPageEntity = aboutPageEntity;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
