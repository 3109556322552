import { FETCH_NAVBAR } from "./navbar.actions";
import { SET_NAVBAR } from "./navbar.mutations";
import navbarModel, { NavbarModuleState, NavbarEntity } from "./navbar.model";

const state: NavbarModuleState = {
  navbarEntity: null,
};

const getters = {
  navbarEntity(state: NavbarModuleState): NavbarEntity {
    return state.navbarEntity;
  },
};

const actions = {
  async [FETCH_NAVBAR]({ commit }, id: string): Promise<void> {
    commit(SET_NAVBAR, await navbarModel.get(id));
  },
};

const mutations = {
  [SET_NAVBAR](state: NavbarModuleState, navbarEntity: NavbarEntity): void {
    state.navbarEntity = navbarEntity;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
