// Contentful Params
export const CONTENTFUL_SPACE_ID = process.env.VUE_APP_CONTENTFUL_SPACE_ID;
export const CONTETFUL_ACCESS_TOKEN =
  process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN;

// Contentful Content
export const UNDER_CONSTRUCTION_PAGE_ENTRY_ID =
  process.env.VUE_APP_UNDER_CONSTRUCTION_PAGE_ENTRY_ID;
export const NAVBAR_ENTRY_ID = process.env.VUE_APP_NAVBAR_ENTRY_ID;
export const FOOTER_ENTRY_ID = process.env.VUE_APP_FOOTER_ENTRY_ID;
export const LANDING_PAGE_ENTRY_ID = process.env.VUE_APP_LANDING_PAGE_ENTRY_ID;
export const SOLAR_PRODUCT_PAGE_ENTRY_ID =
  process.env.VUE_APP_SOLAR_PRODUCT_PAGE_ENTRY_ID;
export const BATTERIES_PRODUCT_PAGE_ENTRY_ID =
  process.env.VUE_APP_BATTERIES_PRODUCT_PAGE_ENTRY_ID;
export const GALLERY_PAGE_ENTRY_ID = process.env.VUE_APP_GALLERY_PAGE_ENTRY_ID;
export const ABOUT_PAGE_ENTRY_ID = process.env.VUE_APP_ABOUT_PAGE_ENTRY_ID;
export const QUOTE_PAGE_ENTRY_ID = process.env.VUE_APP_QUOTE_PAGE_ENTRY_ID;
export const CONTACT_DETAILS_ENTRY_ID =
  process.env.VUE_APP_CONTACT_DETAILS_ENTRY_ID;
export const CONDITIONS_PAGE_ENTRY_ID =
  process.env.VUE_APP_CONDITIONS_PAGE_ENTRY_ID;
export const PRIVACY_PAGE_ENTRY_ID = process.env.VUE_APP_PRIVACY_PAGE_ENTRY_ID;
export const DEALS_PAGE_ENTRY_ID = process.env.VUE_APP_DEALS_PAGE_ENTRY_ID;
