import { FETCH_DEALS_PAGE } from "./deals-page.actions";
import { SET_DEALS_PAGE } from "./deals-page.mutations";
import dealsPageModel, {
  DealsPageModuleState,
  DealsPageEntity,
} from "./deals-page.model";

const state: DealsPageModuleState = {
  dealsPageEntity: null,
};

const getters = {
  dealsPageEntity(state: DealsPageModuleState): DealsPageEntity {
    return state.dealsPageEntity;
  },
};

const actions = {
  async [FETCH_DEALS_PAGE]({ commit }, id: string): Promise<void> {
    commit(SET_DEALS_PAGE, await dealsPageModel.get(id));
  },
};

const mutations = {
  [SET_DEALS_PAGE](
    state: DealsPageModuleState,
    dealsPageEntity: DealsPageEntity
  ): void {
    state.dealsPageEntity = dealsPageEntity;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
