import { createStore } from "vuex";
import underConstructionPage from "@/store/modules/under-construction-page/under-construction-page.module";
import navbar from "@/store/modules/navbar/navbar.module";
import landingPage from "@/store/modules/landing-page/landing-page.module";
import footer from "@/store/modules/footer/footer.module";
import productPage from "@/store/modules/product-page/product-page.module";
import galleryPage from "@/store/modules/gallery-page/gallery-page.module";
import aboutPage from "@/store/modules/about-page/about-page.module";
import quotePage from "@/store/modules/quote-page/quote-page.module";
import contactDetails from "@/store/modules/contact-details/contact-details.module";
import conditionsPage from "@/store/modules/conditions-page/conditions-page.module";
import privacyPage from "@/store/modules/privacy-page/privacy-page.module";
import dealsPage from "@/store/modules/deals-page/deals-page.module";

export default createStore({
  modules: {
    underConstructionPage,
    navbar,
    landingPage,
    footer,
    productPage,
    galleryPage,
    contactDetails,
    aboutPage,
    quotePage,
    conditionsPage,
    privacyPage,
    dealsPage,
  },
});
