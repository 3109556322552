
import "./applink.scss";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: "Applink",
  inheritAttrs: false,
})
export default class Applink extends Vue {
  //#region Component
  @Prop() to: string;
  @Prop({ default: "applink--active" }) activeClass: string;
  @Prop({ default: "applink--inactive" }) inactiveClass: string;

  //#endregion

  //#region Functions
  get isExternalLink(): boolean {
    return typeof this.to === "string" && this.to.startsWith("http");
  }

  get isTelLink(): boolean {
    return typeof this.to === "string" && this.to.startsWith("tel");
  }

  get isMailToLink(): boolean {
    return typeof this.to === "string" && this.to.startsWith("mailto");
  }
  //#endregion
}
