import {
  ContentfulEntity,
  ContentfulEntityImageFields,
  ContentfulEntityGalleryFields,
  ContentfulEntityProductFields,
} from "@/store/contentful.types";
import {
  GalleryBlock,
  ProductBlock,
  ProductResource,
} from "@/store/common.types";

export function getImageUrlFromContentfulEntity(
  contentfulEntity: ContentfulEntity
): string | null {
  const imageFields = <ContentfulEntityImageFields>contentfulEntity?.fields;
  return imageFields?.file.url;
}

export async function getGalleryBlockFromContentfulEntity(
  contentfulEntity: ContentfulEntity
): Promise<GalleryBlock> {
  const galleryBlock: GalleryBlock = {
    headerText: "",
    text: "",
    images: [],
  };

  const galleryFields = <ContentfulEntityGalleryFields>contentfulEntity.fields;

  galleryBlock.headerText = galleryFields.galleryBlockHeaderText;
  galleryBlock.text =
    galleryFields.galleryBlockText.content[0].content[0].value;
  galleryBlock.images = galleryFields.galleryBlockImages;

  return galleryBlock;
}

export async function getProductBlockFromContentfulEntity(
  contentfulEntity: ContentfulEntity
): Promise<ProductBlock> {
  const productBlock: ProductBlock = {
    logoImageUrl: "",
    imageUrl: "",
    headerText: "",
    text: "",
    resources: [],
  };

  const productFields = <ContentfulEntityProductFields>contentfulEntity.fields;

  const logoImageFields = <ContentfulEntityImageFields>(
    productFields.productBlockLogoImage.fields
  );
  productBlock.logoImageUrl = logoImageFields.file.url;

  const imageFields = <ContentfulEntityImageFields>(
    productFields.productBlockImage.fields
  );
  productBlock.imageUrl = imageFields.file.url;

  productBlock.headerText = productFields.productBlockHeaderText;

  productBlock.text =
    productFields.productBlockText.content[0].content[0].value;

  productFields.productBlockResources.forEach(async (resource) => {
    const productResource: ProductResource = {
      entryId: resource.sys.id,
      name: resource.sys.id,
      url: resource.sys.id,
    };
    productBlock.resources.push(productResource);
  });

  return productBlock;
}
