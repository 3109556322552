import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faCaretDown as fasFreeCaretDown,
  faAngleDown as fasFreeAngleDown,
  faPhone as fasPhone,
  faEnvelope as fasEnvelope,
  faFilePdf as fasFilePdf,
  faBars as fasBars,
} from "@fortawesome/free-solid-svg-icons";

import {
  faFacebookSquare,
  faInstagramSquare,
  faTwitterSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

export function setupFaLibrary(): void {
  // free-solid-svg-icons
  library.add(fasFreeCaretDown);
  library.add(fasFreeAngleDown);
  library.add(fasPhone);
  library.add(fasEnvelope);
  library.add(fasFilePdf);
  library.add(fasBars);

  // free-brands-svg-icons
  library.add(faFacebookSquare);
  library.add(faInstagramSquare);
  library.add(faTwitterSquare);
  library.add(faLinkedin);
}
