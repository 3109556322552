import api from "@/store/api";
import { ContentfulEntityRichText } from "@/store/contentful.types";

export type PrivacyPageModuleState = {
  privacyPolicyPageEntity: PrivacyPageEntity;
};

export interface PrivacyPageEntity {
  id: string | null;
  privacyPolicyPageHeaderText: string;
  privacyPolicyPageRichText: ContentfulEntityRichText;
}

export class PrivacyPageEntity {
  constructor({
    id = null,
    privacyPolicyPageHeaderText = null,
    privacyPolicyPageRichText = null,
  } = {}) {
    this.id = id;
    this.privacyPolicyPageHeaderText = privacyPolicyPageHeaderText;
    this.privacyPolicyPageRichText = privacyPolicyPageRichText;
  }
}

export function responseAdapter(response) {
  const { fields, sys } =
    response.sys.type === "Array" ? response.items[0] : response;

  return new PrivacyPageEntity({ ...fields, ...sys });
}

export default {
  async get(id) {
    return responseAdapter(await api.getEntries({ "sys.id": id }));
  },
};
