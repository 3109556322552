import api from "@/store/api";
import {
  ContentfulEntity,
  ContentfulEntityRichText,
} from "@/store/contentful.types";

export type AboutPageModuleState = {
  aboutPageEntity: AboutPageEntity;
};

export interface AboutPageEntity {
  id: string | null;
  aboutPageSplashImage: ContentfulEntity;
  aboutPageSplashText: string;
  aboutPageHeader: string;
  aboutPageRichText: ContentfulEntityRichText;
  aboutPageAboutBlocks: Array<ContentfulEntity>;
}

export class AboutPageEntity {
  constructor({
    id = null,
    aboutPageSplashImage = null,
    aboutPageSplashText = null,
    aboutPageHeader = null,
    aboutPageRichText = null,
    aboutPageAboutBlocks = null,
  } = {}) {
    this.id = id;
    this.aboutPageSplashImage = aboutPageSplashImage;
    this.aboutPageSplashText = aboutPageSplashText;
    this.aboutPageHeader = aboutPageHeader;
    this.aboutPageRichText = aboutPageRichText;
    this.aboutPageAboutBlocks = aboutPageAboutBlocks;
  }
}

export function responseAdapter(response) {
  const { fields, sys } =
    response.sys.type === "Array" ? response.items[0] : response;

  return new AboutPageEntity({ ...fields, ...sys });
}

export default {
  async get(id) {
    return responseAdapter(await api.getEntries({ "sys.id": id }));
  },
};
