import { FETCH_GALLERY_PAGE } from "./gallery-page.actions";
import { SET_GALLERY_PAGE } from "./gallery-page.mutations";
import galleryPageModel, {
  GalleryPageModuleState,
  GalleryPageEntity,
} from "./gallery-page.model";

const state: GalleryPageModuleState = {
  galleryPageEntity: null,
};

const getters = {
  galleryPageEntity(state: GalleryPageModuleState): GalleryPageEntity {
    return state.galleryPageEntity;
  },
};

const actions = {
  async [FETCH_GALLERY_PAGE]({ commit }, id: string): Promise<void> {
    commit(SET_GALLERY_PAGE, await galleryPageModel.get(id));
  },
};

const mutations = {
  [SET_GALLERY_PAGE](
    state: GalleryPageModuleState,
    galleryPageEntity: GalleryPageEntity
  ): void {
    state.galleryPageEntity = galleryPageEntity;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
