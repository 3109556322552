import { createApp } from "vue";
import app from "./app.vue";
import router from "./router";
import store from "./store";

const app_ = createApp(app);
app_.use(store);
app_.use(router);

// animate.css
import "animate.css";

// vue-toast
import VueToast from "vue-toast-notification";
//import 'vue-toast-notification/dist/theme-default.css';
// import "vue-toast-notification/dist/theme-sugar.css";
import "@/scss/vue-notification-theme.scss";

app_.use(VueToast);

// Font Awesome 5 for Vue 3.x
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();

app_.component("font-awesome-icon", FontAwesomeIcon);

//Font Awesome library setup
import { setupFaLibrary } from "@/fa";
setupFaLibrary();

// vue-meta and analytics
import { createMetaManager } from "vue-meta";
import firebase from "firebase/app";
import "firebase/analytics";

app_.use(createMetaManager());

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_WEB_API_KEY,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_ANALYTICS_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
app_.config.globalProperties.$analytics = firebase.analytics();

app_.mount("#app");
