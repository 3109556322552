import api from "@/store/api";
import { ContentfulEntity } from "@/store/contentful.types";
import { NavbarLinksModel } from "@/components/navbar/navbar.types";

export type NavbarModuleState = {
  navbarEntity: NavbarEntity;
};

export interface NavbarEntity {
  id: string | null;
  navbarLogoImage: ContentfulEntity;
  navbarLinksModel: NavbarLinksModel;
}

export class NavbarEntity {
  constructor({
    id = null,
    navbarLogoImage = null,
    navbarLinksModel = null,
  } = {}) {
    this.id = id;
    this.navbarLogoImage = navbarLogoImage;
    this.navbarLinksModel = navbarLinksModel;
  }
}

export function responseAdapter(response) {
  const { fields, sys } =
    response.sys.type === "Array" ? response.items[0] : response;

  return new NavbarEntity({ ...fields, ...sys });
}

export default {
  async get(id) {
    return responseAdapter(await api.getEntries({ "sys.id": id }));
  },
};
