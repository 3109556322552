import api from "@/store/api";
import { ContentfulEntityRichText } from "@/store/contentful.types";

export type ContactDetailsModuleState = {
  contactDetailsEntity: ContactDetailsEntity;
};

export interface ContactDetailsEntity {
  id: string | null;
  contactDetailsFullNameText: string;
  contactDetailsFirstNameText: string;
  contactDetailsLastNameText: string;
  contactDetailsEmailText: string;
  contactDetailsPhoneFormattedText: string;
  contactDetailsPhoneExtendedText: string;
  contactDetailsAddressText: string;
  contactDetailsBusinessHoursRichText: ContentfulEntityRichText;
  contactDetailsFacebookUrl: string;
  contactDetailsInstagramUrl: string;
  contactDetailsLinkedinUrl: string;
}

export class ContactDetailsEntity {
  constructor({
    id = null,
    contactDetailsFullNameText = null,
    contactDetailsFirstNameText = null,
    contactDetailsLastNameText = null,
    contactDetailsEmailText = null,
    contactDetailsPhoneFormattedText = null,
    contactDetailsPhoneExtendedText = null,
    contactDetailsAddressText = null,
    contactDetailsBusinessHoursRichText = null,
    contactDetailsFacebookUrl = null,
    contactDetailsInstagramUrl = null,
    contactDetailsLinkedinUrl = null,
  } = {}) {
    this.id = id;
    this.contactDetailsFullNameText = contactDetailsFullNameText;
    this.contactDetailsFirstNameText = contactDetailsFirstNameText;
    this.contactDetailsLastNameText = contactDetailsLastNameText;
    this.contactDetailsEmailText = contactDetailsEmailText;
    this.contactDetailsPhoneFormattedText = contactDetailsPhoneFormattedText;
    this.contactDetailsPhoneExtendedText = contactDetailsPhoneExtendedText;
    this.contactDetailsAddressText = contactDetailsAddressText;
    this.contactDetailsBusinessHoursRichText =
      contactDetailsBusinessHoursRichText;
    this.contactDetailsFacebookUrl = contactDetailsFacebookUrl;
    this.contactDetailsInstagramUrl = contactDetailsInstagramUrl;
    this.contactDetailsLinkedinUrl = contactDetailsLinkedinUrl;
  }
}

export function responseAdapter(response) {
  const { fields, sys } =
    response.sys.type === "Array" ? response.items[0] : response;

  return new ContactDetailsEntity({ ...fields, ...sys });
}

export default {
  async get(id) {
    return responseAdapter(await api.getEntries({ "sys.id": id }));
  },
};
