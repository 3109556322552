import { FETCH_CONDITIONS_PAGE } from "./conditions-page.actions";
import { SET_CONDITIONS_PAGE } from "./conditions-page.mutations";
import conditionsPageModel, {
  ConditionsPageModuleState,
  ConditionsPageEntity,
} from "./conditions-page.model";

const state: ConditionsPageModuleState = {
  conditionsPageEntity: null,
};

const getters = {
  conditionsPageEntity(state: ConditionsPageModuleState): ConditionsPageEntity {
    return state.conditionsPageEntity;
  },
};

const actions = {
  async [FETCH_CONDITIONS_PAGE]({ commit }, id: string): Promise<void> {
    commit(SET_CONDITIONS_PAGE, await conditionsPageModel.get(id));
  },
};

const mutations = {
  [SET_CONDITIONS_PAGE](
    state: ConditionsPageModuleState,
    conditionsPageEntity: ConditionsPageEntity
  ): void {
    state.conditionsPageEntity = conditionsPageEntity;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
