<template>
  <div v-if="!this.isLoading">
    <div id="mobile-menu" class="mobile-menu">
      <div class="mobile-menu__content">
        <ul>
          <li v-for="item in this.navbarLinksModel.model" :key="item.label">
            <Applink
              :key="item.label"
              :to="item.to"
              :activeClass="{
                'applink--active--highlight': item.highlight,
                'applink--active': !item.highlight,
              }"
              :inactiveClass="{
                'applink--inactive--highlight': item.highlight,
                'applink--inactive': !item.highlight,
              }"
              @click="onClickMenuItem"
            >
              {{ item.label }}
            </Applink>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import "./mobile-menu.scss";
import { Options, Vue } from "vue-class-component";
import { h, resolveComponent } from "vue";
// import { Prop, Watch } from "vue-property-decorator";
import Applink from "@/components/applink/applink.vue";
import { getImageUrlFromContentfulEntity } from "@/store/utils";
import { FETCH_NAVBAR } from "@/store/modules/navbar/navbar.actions";
import { NAVBAR_ENTRY_ID } from "@/store/api.config";
import { NavbarEntity } from "@/store/modules/navbar/navbar.model";
import { NavbarLinksModel } from "./navbar.types";

import { NMenu } from "naive-ui";
import { RouterLink } from "vue-router";

@Options({
  name: "MobileMenu",
  components: {
    Applink,
    NMenu,
  },
})
export default class MobileMenu extends Vue {
  //#region Component
  isLoading = true;
  navbarEntity: NavbarEntity | null = null;
  navbarLinksModel: NavbarLinksModel | null = null;

  isMobileMenuActive = false;
  activeKey: null;
  menuOptions: Record<string, any> = [];
  //#endregion

  //#region Hooks
  created(): void {
    this.setup();
  }

  async setup(): Promise<void> {
    await this.$store.dispatch(FETCH_NAVBAR, NAVBAR_ENTRY_ID);
    this.navbarEntity = this.$store.getters.navbarEntity;
    this.navbarLinksModel = this.navbarEntity.navbarLinksModel;

    this.menuOptions = this.navbarLinksModel.model.map((navItem) => ({
      label: () =>
        h(
          RouterLink,
          { to: { path: navItem.to } },
          { default: () => navItem.label }
        ),
      key: navItem.label,
      children: navItem.items?.map((navItemChild) => ({
        label: () =>
          h(
            RouterLink,
            { to: { path: navItemChild.to } },
            { default: () => navItemChild.label }
          ),
        key: navItemChild.label,
      })),
    }));

    this.isLoading = false;
  }
  //#endregion

  //#region Functions
  getNavbarLogoImageUrl(): string {
    return getImageUrlFromContentfulEntity(this.navbarEntity.navbarLogoImage);
  }

  onClickMenuItem(): void {
    this.$emit("onClickMenuItem");
    this.onToggleMobileMenu();
  }

  onToggleMobileMenu(): void {
    this.isMobileMenuActive = !this.isMobileMenuActive;
    if (this.isMobileMenuActive) {
      this.showMobileMenu();
    } else {
      this.hideMobileMenu();
    }
  }

  showMobileMenu(): void {
    document.getElementById("mobile-menu").style.height = "100%";
  }

  hideMobileMenu(): void {
    document.getElementById("mobile-menu").style.height = "0%";
  }
  //#endregion
}
</script>
