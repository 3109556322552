import api from "@/store/api";
import { ContentfulEntity } from "@/store/contentful.types";

export type GalleryPageModuleState = {
  galleryPageEntity: GalleryPageEntity;
};

export interface GalleryPageEntity {
  id: string | null;
  galleryPageGalleryBlocks: Array<ContentfulEntity>;
}

export class GalleryPageEntity {
  constructor({ id = null, galleryPageGalleryBlocks = [] } = {}) {
    this.id = id;
    this.galleryPageGalleryBlocks = galleryPageGalleryBlocks;
  }
}

export function responseAdapter(response) {
  const { fields, sys } =
    response.sys.type === "Array" ? response.items[0] : response;

  return new GalleryPageEntity({ ...fields, ...sys });
}

export default {
  async get(id) {
    return responseAdapter(await api.getEntries({ "sys.id": id }));
  },
};
