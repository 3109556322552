import api from "@/store/api";
import { ContentfulEntityRichText } from "@/store/contentful.types";

export type QuotePageModuleState = {
  quotePageEntity: QuotePageEntity;
};

export interface QuotePageEntity {
  id: string | null;
  quotePageHeaderText: string;
  quotePageRichText: ContentfulEntityRichText;
}

export class QuotePageEntity {
  constructor({
    id = null,
    quotePageHeaderText = null,
    quotePageRichText = null,
  } = {}) {
    this.id = id;
    this.quotePageHeaderText = quotePageHeaderText;
    this.quotePageRichText = quotePageRichText;
  }
}

export function responseAdapter(response) {
  const { fields, sys } =
    response.sys.type === "Array" ? response.items[0] : response;

  return new QuotePageEntity({ ...fields, ...sys });
}

export default {
  async get(id) {
    return responseAdapter(await api.getEntries({ "sys.id": id }));
  },
};
