import api from "@/store/api";
import { ContentfulEntity } from "@/store/contentful.types";

export type FooterModuleState = {
  footerEntity: FooterEntity;
};

export interface FooterEntity {
  id: string | null;
  footerLogoImages: Array<ContentfulEntity>;
  footerQualificationImages: Array<ContentfulEntity>;
  footerCopyrightText: string;
}

export class FooterEntity {
  constructor({
    id = null,
    footerLogoImages = [],
    footerQualificationImages = [],
    footerCopyrightText = null,
  } = {}) {
    this.id = id;
    this.footerLogoImages = footerLogoImages;
    this.footerQualificationImages = footerQualificationImages;
    this.footerCopyrightText = footerCopyrightText;
  }
}

export function responseAdapter(response) {
  const { fields, sys } =
    response.sys.type === "Array" ? response.items[0] : response;

  return new FooterEntity({ ...fields, ...sys });
}

export default {
  async get(id) {
    return responseAdapter(await api.getEntries({ "sys.id": id }));
  },
};
