import { FETCH_UNDER_CONSTRUCTION_PAGE } from "./under-construction-page.actions";
import { SET_UNDER_CONSTRUCTION_PAGE } from "./under-construction-page.mutations";
import underConstructionModel, {
  UnderConstructionPageModuleState,
  UnderConstructionPageEntity,
} from "./under-construction-page.model";

const state: UnderConstructionPageModuleState = {
  underConstructionPageEntity: null,
};

const getters = {
  underConstructionPageEntity(
    state: UnderConstructionPageModuleState
  ): UnderConstructionPageEntity {
    return state.underConstructionPageEntity;
  },
};

const actions = {
  async [FETCH_UNDER_CONSTRUCTION_PAGE]({ commit }, id: string): Promise<void> {
    commit(SET_UNDER_CONSTRUCTION_PAGE, await underConstructionModel.get(id));
  },
};

const mutations = {
  [SET_UNDER_CONSTRUCTION_PAGE](
    state: UnderConstructionPageModuleState,
    underConstructionPageEntity: UnderConstructionPageEntity
  ): void {
    state.underConstructionPageEntity = underConstructionPageEntity;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
