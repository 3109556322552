import { FETCH_PRIVACY_PAGE } from "./privacy-page.actions";
import { SET_PRIVACY_PAGE } from "./privacy-page.mutations";
import privacyPolicyPageModel, {
  PrivacyPageModuleState,
  PrivacyPageEntity,
} from "./privacy-page.model";

const state: PrivacyPageModuleState = {
  privacyPolicyPageEntity: null,
};

const getters = {
  privacyPolicyPageEntity(state: PrivacyPageModuleState): PrivacyPageEntity {
    return state.privacyPolicyPageEntity;
  },
};

const actions = {
  async [FETCH_PRIVACY_PAGE]({ commit }, id: string): Promise<void> {
    commit(SET_PRIVACY_PAGE, await privacyPolicyPageModel.get(id));
  },
};

const mutations = {
  [SET_PRIVACY_PAGE](
    state: PrivacyPageModuleState,
    privacyPolicyPageEntity: PrivacyPageEntity
  ): void {
    state.privacyPolicyPageEntity = privacyPolicyPageEntity;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
