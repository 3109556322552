
import "./app.scss";
import { Options, setup, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { useActiveMeta, useMeta } from "vue-meta";
import firebase from "firebase/app";
import Navbar from "@/components/navbar/navbar.vue";
import MobileMenu from "@/components/mobile-menu/mobile-menu.vue";
import Appview from "@/components/appview/appview.vue";
import { FETCH_CONTACT_DETAILS } from "@/store/modules/contact-details/contact-details.actions";
import { CONTACT_DETAILS_ENTRY_ID } from "@/store/api.config";

@Options({
  name: "App",
  components: {
    Navbar,
    MobileMenu,
    Appview,
  },
})
export default class App extends Vue {
  //#region Meta
  meta = setup(() =>
    useMeta({
      title: "SpartanEnergyWebapp",
      htmlAttrs: { lang: "en", amp: true },
    })
  );

  metadata = setup(() => useActiveMeta());

  @Watch("metadata.title")
  onChangedMetadataTitle(newValue: string): void {
    firebase.analytics().setCurrentScreen(newValue);
    firebase.analytics().logEvent("page_view");
    firebase.analytics().logEvent("screen_view", {
      app_name: process.env.VUE_APP_APPLICATION_NAME,
      screen_name: newValue,
      app_version: process.env.VUE_APP_APPLICATION_VERSION,
    });
  }
  //#endregion

  $refs!: {
    navBar: any;
    mobileMenu: any;
  };

  //#region Component
  isLoading = true;
  //#endregion

  //#region Hooks
  created(): void {
    this.setup();
  }

  async setup(): Promise<void> {
    await this.$store.dispatch(FETCH_CONTACT_DETAILS, CONTACT_DETAILS_ENTRY_ID);
    this.isLoading = false;
  }
  //#endregion

  //#region Functions
  isUnderConstruction(): boolean {
    return this.$route.name === "UnderConstructionPage";
  }

  onClickMenuItem(): void {
    this.$refs.navBar.resetMobileMenu();
  }

  onToggleMobileMenu(): void {
    console.log("onToggleMobileMenu");
    this.$refs.mobileMenu.onToggleMobileMenu();
  }
  //#endregion
}
App.registerHooks([
  "beforeRouteEnter",
  "beforeRouteUpdate",
  "beforeRouteLeave",
]);
