<template>
  <div class="appview">
    <router-view class="appview__content" />
  </div>
</template>

<script lang="ts">
import "./appview.scss";
import { Options, Vue } from "vue-class-component";

@Options({
  name: "Appview",
})
export default class Appview extends Vue {}
</script>
