import api from "@/store/api";
import {
  ContentfulEntity,
  ContentfulEntityRichText,
} from "@/store/contentful.types";

export type DealsPageModuleState = {
  dealsPageEntity: DealsPageEntity;
};

export interface DealsPageEntity {
  id: string | null;
  dealsPageSplashImage: ContentfulEntity;
  dealsPageSplashText: string;
  dealsPageHeaderText: string;
  dealsPageRichText: ContentfulEntityRichText;
}

export class DealsPageEntity {
  constructor({
    id = null,
    dealsPageSplashImage = null,
    dealsPageSplashText = null,
    dealsPageHeaderText = null,
    dealsPageRichText = null,
  } = {}) {
    this.id = id;
    this.dealsPageSplashImage = dealsPageSplashImage;
    this.dealsPageSplashText = dealsPageSplashText;
    this.dealsPageHeaderText = dealsPageHeaderText;
    this.dealsPageRichText = dealsPageRichText;
  }
}

export function responseAdapter(response) {
  const { fields, sys } =
    response.sys.type === "Array" ? response.items[0] : response;

  return new DealsPageEntity({ ...fields, ...sys });
}

export default {
  async get(id) {
    return responseAdapter(await api.getEntries({ "sys.id": id }));
  },
};
